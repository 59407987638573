import React, { useState } from 'react';
import JsonViewer from '../../JsonViewer';
import { callApi } from '../../../utils';

function CustomerEnquries() {
  const [meterNumber, setMeterNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [receiptNumber, setReceiptNumber] = useState('');
  const [result, setResult] = useState([]);

  function fetchData({
    receiptNumber,
    meterNumber,
    accountNumber,
    dateFrom,
    dateTo,
  }) {
    return callApi(`/ekedc/customer/transactions`, {
      receiptNumber,
      meterNumber,
      accountNumber,
      dateFrom,
      dateTo,
    }).then((res) => res);
  }

  function handleInputChange(evt) {
    const name = evt.target.name;
    const value = evt.target.value;

    switch (name) {
      case 'receiptNumber':
        setReceiptNumber(value);
        break;

      case 'meterNumber':
        setMeterNumber(value);
        break;

      case 'accountNumber':
        setAccountNumber(value);
        break;

      case 'dateTo':
        setDateTo(value);
        break;

      case 'dateFrom':
        setDateFrom(value);
        break;

      default:
        break;
    }
  }

  function handleFetchClick() {
    fetchData({
      receiptNumber,
      meterNumber,
      accountNumber,
      dateFrom,
      dateTo,
    }).then((result) => {
      setResult(result || []);
    });
  }

  return (
    <div>
      <div>
        <input
          name="receiptNumber"
          value={receiptNumber}
          onChange={handleInputChange}
          placeholder="receipt number"
        />
        <input
          name="meterNumber"
          value={meterNumber}
          onChange={handleInputChange}
          placeholder="meter number"
        />
        <input
          name="accountNumber"
          value={accountNumber}
          onChange={handleInputChange}
          placeholder="account number"
        />
        <input
          name="dateFrom"
          value={dateFrom}
          onChange={handleInputChange}
          placeholder="date from"
        />
        <input
          name="dateTo"
          value={dateTo}
          onChange={handleInputChange}
          placeholder="date to"
        />

        <button onClick={handleFetchClick}>Fetch Data</button>
      </div>

      <JsonViewer
        src={result}
        name={'Customer Enquiries'}
        iconStyle={'circle'}
        displayDataTypes={false}
        displayObjectSize={false}
      />
    </div>
  );
}

export default CustomerEnquries;
