import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConfirmActionModal from '../ConfirmActionModal';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import classnames from 'classnames';

import { Switch, Route } from 'react-router-dom';

import { callApi } from '../../utils';
import { showError } from '../../actions/notificationActions';

import { openUserModal } from '../../actions/modalActions';

import { GeneralTab } from './GeneralTab';
import MerchantWallet from './MerchantWallet';
import MerchantOrders from './MerchantOrders';
import MerchantCommission from './MerchantCommission';
import Loading from '../Loading';

class Detail extends Component {
  state = {
    merchant: {},
    meta: {},
    vendResponse: {},
    paymentResponse: {},
    loading: true,
    activeTab: '1',
    toggle: null,
    transactions: [],
    page: 1,
    limit: 10,
  };

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    const id = this.props.match.params.id;

    this.startLoad();
    callApi(`/merchants/${id}`)
      .then(({ data = {}, meta = {} }) => {
        this.setState({ merchant: data, meta });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  opentUser = (id) => {
    this.props.dispatch(openUserModal(id));
  };

  render() {
    let { merchant, loading } = this.state;

    const { history, match, location } = this.props;

    return (
      <div className="content">
        <ConfirmActionModal />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>{merchant.name || 'Loading...'}</CardTitle>
                    <p className="category">{merchant.id}</p>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}`);
                        }}
                      >
                        Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}/wallet`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/wallet`);
                        }}
                      >
                        Wallet
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}/orders`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/orders`);
                        }}
                      >
                        Orders
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active:
                            location.pathname === `${match.url}/commissions`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/commissions`);
                        }}
                      >
                        Commissions
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  <div>
                    {loading ? (
                      <Loading />
                    ) : (
                      <React.Fragment>
                        <Switch>
                          <Route exact path={`${match.path}`}>
                            <GeneralTab merchant={merchant} />
                          </Route>
                          <Route exact path={`${match.path}/wallet`}>
                            <MerchantWallet
                              merchant={merchant}
                              id={merchant.id}
                            />
                          </Route>
                          <Route exact path={`${match.path}/orders`}>
                            <MerchantOrders
                              merchant={merchant}
                              id={merchant.id}
                            />
                          </Route>

                          <Route exact path={`${match.path}/commissions`}>
                            <MerchantCommission merchant={merchant} />
                          </Route>
                        </Switch>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Detail);
