import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
} from 'reactstrap';

import { callApi, formatDate } from '../../utils';
import { showError } from '../../actions/notificationActions';

import Loading from '.././Loading';
import Pagination from '.././Pagination';
import SearchForm from '.././SearchForm';

class List extends Component {
  state = {
    rows: [],
    q: '',
    page: 1,
    limit: 30,
    loading: false,
  };

  componentWillMount() {
    this.loadTransactions();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadTransactions = (e) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();

    const { q, page, limit } = this.state;
    this.startLoad();

    callApi(`/merchants`, { q, page, limit })
      .then(({ data = [] }) => {
        this.setState({ loading: false, rows: data });
        this.scrollToTop();
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadTransactions();
      });
    }
  };

  setQuery = (e) => {
    this.setState({ q: e.target.value });
  };

  openTransaction = (id) => {
    // this.props.dispatch(openTransactionModal({id}));
    this.props.history.push(`/merchants/${id}`);
  };

  render() {
    const { q, rows, page, loading, limit } = this.state;
    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <CardTitle>Merchants</CardTitle>
                <p className="category">Merchants - Page {page}</p>
              </CardHeader>
              <SearchForm
                value={q}
                onChange={this.setQuery}
                onSearch={this.loadTransactions}
                loading={loading}
              />
              <CardBody>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Joined</th>
                      <th>Merchant ID</th>
                      <th>Email</th>
                      <th>Disco ID</th>
                    </tr>
                  </thead>

                  <tbody>
                    {rows.map((t, i) => (
                      <tr onClick={() => this.openTransaction(t.id)} key={t.id}>
                        <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                        <td>{t.name}</td>
                        <td>{t.phone}</td>
                        <td>{formatDate(t.created_at)}</td>
                        <td>{t.id}</td>
                        <td>{t.email}</td>
                        <td>{t.disco_id}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination page={page} onChange={this.setPage} />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(List);
