import { Col, Row, Table } from 'reactstrap';
import { callApi, formatAmount } from '../../utils';

import EditMerchantCommissionModal from './EditMerchantCommissionModal';
import Loading from '../Loading';
import React from 'react';
import { showError } from '../../actions/notificationActions';

class MerchantCommission extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openEditCommissionModal: false,
      editCommission: null,
      loading: true,
      rows: [],
    };
  }

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    e && e.preventDefault();

    const { merchant } = this.props;

    this.startLoad();

    callApi(`/merchants/${merchant.id}/commissions`)
      .then(({ data = [] }) => {
        this.setState({ rows: data });
        this.scrollToTop();
      })
      .catch((err) => {
        showError(err);
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  openEditCommissionModal = (row) => {
    this.setState({
      openEditCommissionModal: true,
      editCommission: row,
    });
  };

  closeEditCommissionModal = () => {
    this.setState({
      openEditCommissionModal: false,
      editCommission: {},
    });
  };

  setQuery = (e) => {
    this.setState({ q: e.target.value });
  };

  setPage = (page) => {
    let { count, limit } = this.state;

    const canLoad = count % ((page - 1) * limit) < count;

    if (canLoad) {
      this.startLoad();
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  render() {
    const { loading, rows } = this.state;
    return (
      <div>
        <Row>
          <Col sm="12">
            {loading ? (
              <Loading />
            ) : (
              <Table responsive hover striped>
                <thead>
                  <tr>
                    <th>Service</th>
                    <th>Commission Earned</th>
                    <th>Transaction Cap</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, i) => (
                    <tr key={row.key}>
                      <td>
                        <span style={{ fontWeight: 'bold' }}>{row.key}</span>
                      </td>
                      <td>
                        <span style={{ fontWeight: 'bold' }}>
                          {row.rate * 100}%
                        </span>
                      </td>
                      <td>
                        <span style={{ fontWeight: 'bold' }}>
                          {formatAmount(row.cap)}
                        </span>
                      </td>
                      <td>
                        <div
                          style={{
                            marginBlock: '2px',
                            background: '#e95e38',
                            display: 'inline-block',
                            color: '#fff',
                            borderRadius: '30px',
                            padding: '10px 15px',
                          }}
                          onClick={() => {
                            this.openEditCommissionModal(row);
                          }}
                        >
                          edit
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
        {this.state.editCommission ? (
          <EditMerchantCommissionModal
            close={this.closeEditCommissionModal}
            open={this.state.openEditCommissionModal}
            merchantId={this.props.merchant.id}
            commission={this.state.editCommission}
            refreshCommission={this.loadView}
          />
        ) : null}
      </div>
    );
  }
}

export default MerchantCommission;
