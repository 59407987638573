import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
} from 'reactstrap';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  confirmAction,
  openEditSurveyModal,
  openSurveyResponseModal,
  toggleModalLoading,
} from '../../actions/modalActions';
import { showError, showInfo } from '../../actions/notificationActions';

import ConfirmActionModal from '../ConfirmActionModal';
import Loading from '../Loading';
import Pagination from '../Pagination';
import SupportMsgModal from '../SupportMsgModal';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { formatDate } from '../../utils';
import surveyService from '../../services/survey.service';

class Detail extends Component {
  state = {
    survey: null,
    meta: {},
    responses: [],
    loading: true,
    loadingModal: false,
    supportMsgLoading: false,
    activeTab: '1',
    toggle: null,
    rows: [],
    page: 1,
    limit: 30,
  };

  componentDidMount() {
    this.loadSurveys();
    this.loadSurveyResponses();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  openSurveyResponse = (survey) => {
    this.props.dispatch(openSurveyResponseModal(survey));
  };

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadSurveys = () => {
    this.startLoad();
    surveyService
      .getSingleSurvey(this.props.match.params.id)
      .then((res) => {
        this.setState({
          loading: false,
          survey: res.data,
        });
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err.message));
      });
  };

  loadSurveyResponses = () => {
    this.startLoad();
    surveyService
      .getSurveyResponses(this.props.match.params.id)
      .then((res) => {
        this.setState({
          loading: false,
          responses: res.data,
        });
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err.message));
      });
  };

  editSurvey = () => {
    this.props.dispatch(openEditSurveyModal(this.props.match.params.id));
  };

  deleteSurvey = () => {
    this.props.dispatch(
      confirmAction({
        title: 'Delete Survey',
        confirmText: 'Are you sure you want to delete this survey?',
        confirmCallback: () => {
          this.toggleModalLoading();
          surveyService
            .deleteSurvey(this.props.match.params.id)
            .then((res) => {
              this.toggleModalLoading();
              this.props.dispatch(showInfo(res.data.message, 'Success'));
              this.props.history.push('/surveys');
            })
            .catch((err) => {
              this.toggleModalLoading();
              this.props.dispatch(showError(err.response.data.message));
            });
        },
      })
    );
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  toggleModalLoading = () => {
    this.props.dispatch(toggleModalLoading(this.state.loadingModal));
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    let { survey, loading, responses, page, limit } = this.state;

    const { history, match, location } = this.props;

    if (!survey) return <React.Fragment />;

    return (
      <div className="content">
        <ConfirmActionModal />
        <SupportMsgModal customer_id={this.props.match.params.id} />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-start">
                  <div>
                    <CardTitle>{survey.title || 'Loading...'}</CardTitle>
                    <p className="category">Single Survey</p>
                  </div>
                  <div>
                    <Button
                      // size="sm"
                      disabled={loading}
                      color={'success'}
                      onClick={this.editSurvey}
                    >
                      Edit
                    </Button>
                    <Button
                      // size="sm"
                      disabled={loading}
                      color={'danger'}
                      onClick={this.deleteSurvey}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}`);
                        }}
                      >
                        General
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  {survey && (
                    <Switch>
                      <Route exact path={`${match.path}`}>
                        {/* <InfoTab survey={survey} responses={responses} /> */}
                        <Row>
                          <Col sm="6">
                            <Card className="card-chart">
                              <CardHeader>
                                <CardTitle className="mt-0">
                                  Survey Details
                                </CardTitle>
                              </CardHeader>
                              <CardBody>
                                <div className="d-flex mb-3 justify-content-between">
                                  <div>
                                    <span className="text-secondary">
                                      Title
                                    </span>
                                  </div>
                                  <div> {survey.title} </div>
                                </div>
                                <div className="d-flex mb-3 justify-content-between">
                                  <div>
                                    <span className="text-secondary">
                                      Status
                                    </span>
                                  </div>
                                  <div>{survey.status.toUpperCase()}</div>
                                </div>
                                <div className="d-flex mb-3 justify-content-between">
                                  <div>
                                    <span className="text-secondary">Type</span>
                                  </div>
                                  <div>{survey.type}</div>
                                </div>
                                <div className="d-flex mb-3 justify-content-between">
                                  <div>
                                    <span className="text-secondary">
                                      Total Responses
                                    </span>
                                  </div>
                                  <div>{responses.length}</div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>

                          <Col sm="6">
                            <Card className="card-chart">
                              <CardHeader>
                                <CardTitle className="mt-0">
                                  Survey Questions
                                </CardTitle>
                              </CardHeader>
                              <CardBody>
                                <div className="d-flex mb-3">
                                  <div>
                                    <span className="text-secondary">
                                      {survey.questions
                                        .slice() // Create a shallow copy of the array
                                        .sort((a, b) => a.index - b.index) // Sort questions by their index
                                        .map((data) => (
                                          <div>
                                            <div>{data.question}</div>
                                            <ol>
                                              {data.options.map((option) => (
                                                <li>{option.text}</li>
                                              ))}
                                            </ol>
                                          </div>
                                        ))}
                                    </span>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>

                          <Col sm="12" className={'mt-4'}>
                            <Card className="card-chart">
                              <CardHeader>
                                <CardTitle className="mt-0">
                                  Survey Responses
                                </CardTitle>
                              </CardHeader>
                              <CardBody>
                                <Table responsive hover striped>
                                  <thead className="text-primary">
                                    <tr>
                                      <th>No.</th>
                                      <th>User</th>
                                      <th>Status</th>
                                      <th>CreatedAt</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {responses.length > 0 ? (
                                      responses.map((response, i) => (
                                        <tr
                                          onClick={() =>
                                            this.openSurveyResponse(response)
                                          }
                                          key={response.id}
                                        >
                                          <td>
                                            {loading
                                              ? 'x'
                                              : i + 1 + (page - 1) * limit}
                                          </td>
                                          <td>
                                            {response.user.name ||
                                              response.user.phone}
                                          </td>
                                          <td>{response.status}</td>
                                          <td>
                                            {formatDate(response.created_at)}
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={4}>No responses yet</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                                <Pagination
                                  page={page}
                                  onChange={this.setPage}
                                />
                                {loading && <Loading />}
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Route>
                    </Switch>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Detail);
