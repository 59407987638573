import AccDetails from './AccDetails';
import AdroitChequesModal from './AdroitChequesModal';
import ApiUsersRefundModal from './ApiUsersRefundModal';
import CampaignModal from './CampaignModal';
import CreateMerchantModal from './CreateMerchantModal';
import CreditMerchantModal from './CreditMerchantModal';
import EditSurveyModal from './EditSurveyModal';
import GenAccountModal from './GenAccountModal';
import GenerateModal from './GenerateModal';
import QMcashModal from './QMcashModal';
import React from 'react';
import RefunWalletModal from './RefunWalletModal';
import RegenerateModal from './RegenerateModal';
import SendPushNotificationModal from './SendPushNotificationModal';
import SessionModal from './SessionModal';
import SupervisorLogsModal from './SupervisorLogsModal';
import SupervisorWalletModal from './SupervisorWalletModal';
import SurveyResponseModal from './SurveyResponseModal';
import TransactionsModal from './TransactionModal';
import UpdateBalanceModal from './UpdateBalanceModal';
import UserModal from './UserModal';
import WalletTopupModal from './WalletTopupModal';

export default () => (
  <div>
    <TransactionsModal />
    <GenerateModal />
    <RegenerateModal />
    <UserModal />
    <QMcashModal />
    <GenAccountModal />
    <SupervisorWalletModal />
    <SupervisorLogsModal />
    <SessionModal />
    <RefunWalletModal />
    <CreditMerchantModal />
    <SendPushNotificationModal />
    <AccDetails />
    <AdroitChequesModal />
    <ApiUsersRefundModal />
    <CreateMerchantModal />
    <UpdateBalanceModal />
    <WalletTopupModal />
    <CampaignModal />
    <SendPushNotificationModal />
    <SurveyResponseModal />
    <EditSurveyModal />
  </div>
);
