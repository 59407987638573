import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Label, Button, FormGroup, Input } from 'reactstrap';

import { callApi } from '../../utils';
import { showError } from '../../actions/notificationActions';
import Pagination from '../Pagination';

import Loading from '../Loading';
import RequestTable from '../VendRequests/Table';

class Transactions extends React.Component {
  state = {
    queryParams: {
      from: '',
      to: '',
      page: 1,
      limit: '30',
    },
    pages: 1,
    meta: { pages: 1 },
    loading: false,
    rows: [],
    users: [],
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      queryParams: { ...this.state.queryParams, [name]: value },
    });
    // setQueryParams({[name]: value})
  };

  setPage = (page) => {
    if (page) {
      this.setState(
        { queryParams: { ...this.state.queryParams, page } },
        () => {
          this.load();
        }
      );
    }
  };

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  load = () => {
    const { from, to, amount, page, limit } = this.state.queryParams;
    const id = this.props.id;
    this.setState({ loading: true });

    callApi(`/service-providers/${id}/requests`, {
      from,
      to,
      amount,
      page,
      limit,
      include: ['order'],
    })
      .then(({ data = [], meta }) => {
        this.setState({ loading: false, rows: data, meta });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  componentWillMount() {
    this.load();
  }

  view(id) {
    this.props.history.push(`/requests/${id}`);
  }

  render() {
    const { rows, queryParams, loading, meta } = this.state;

    const { from, to } = queryParams;

    return (
      <Row>
        <Col md="12" xs={12}>
          <div className="mt-10">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.getTransactions();
              }}
              className="mb-20"
            >
              <Row colSpan={2} style={{ padding: '0 25px' }}>
                <Col xs="6">
                  <FormGroup>
                    <Label>From</Label>
                    <Input
                      onChange={this.setInput}
                      value={from}
                      name="from"
                      placeholder="From"
                      type="date"
                    />
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label>To</Label>
                    <Input
                      onChange={this.setInput}
                      value={to}
                      name="to"
                      placeholder="To"
                      type="date"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Button size="lg" color="primary" onClick={this.load}>
                  {loading ? 'PLEASE WAIT...' : 'Query'}
                </Button>
              </Row>
            </form>

            {loading ? (
              <Loading />
            ) : (
              <div>
                <RequestTable
                  exclude={['gateway']}
                  rows={rows}
                  page={queryParams.page}
                  loading={loading}
                  limit={queryParams.limit}
                  onRowClick={(row) => this.view(row.id)}
                />
                <Pagination
                  total={meta.pages}
                  page={queryParams.page}
                  onChange={this.setPage}
                />
                {loading && <Loading />}
              </div>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

export default connect()(Transactions);
