import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Nav,
  NavLink,
  NavItem,
} from 'reactstrap';
import classnames from 'classnames';

import { callApi, formatAmount, formatDate } from '../../utils';
import { showError } from '../../actions/notificationActions';

import { openUserModal } from '../../actions/modalActions';

import { Route, Switch } from 'react-router-dom';
import PromoUsers from './PromoUsers';
import PromoTransactions from './PromoTransactions';
import Vouchers from './Vouchers';

class Detail extends Component {
  state = {
    entity: {},
    meta: {},

    loading: true,
    activeTab: '1',
    toggle: null,
    rows: [],
    page: 1,
    pages: 1,
    limit: 10,
  };

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    const id = this.props.match.params.id;

    this.startLoad();
    callApi(`/promotions/${id}`)
      .then(({ data = {}, meta = {} }) => {
        this.setState({ entity: data });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  opentUser = (id) => {
    this.props.dispatch(openUserModal(id));
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    let { entity, meta } = this.state;

    // const toggle = (tab) => {
    //   this.setState({ activeTab: tab });
    // };

    const { history, match, location } = this.props;
    const { id } = match.params;

    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>{entity.label || 'Loading...'}</CardTitle>
                    <p className="category">Promotion</p>
                  </div>
                  <div>
                    <Button
                      onClick={this.confirmRegenerate}
                      disabled={!meta.canRegenerate}
                      color="primary"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}`);
                        }}
                      >
                        General
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}/users`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/users`);
                        }}
                      >
                        Users
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active:
                            location.pathname ===
                            `${match.url}/promo-transactions`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/promo-transactions`);
                        }}
                      >
                        Transactions
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}/vouchers`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/vouchers`);
                        }}
                      >
                        Vouchers
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  <div>
                    <Switch>
                      <Route exact path={`${match.path}`}>
                        <GeneralTab entity={entity} />
                      </Route>
                      <Route path={`${match.path}/users`}>
                        <PromoUsers id={id} />
                      </Route>
                      <Route
                        path={`${match.path}/promo-transactions`}
                        render={(props) => (
                          <PromoTransactions {...props} id={id} />
                        )}
                      ></Route>
                      <Route
                        path={`${match.path}/vouchers`}
                        render={(props) => (
                          <Vouchers {...props} id={id} promotion={entity} />
                        )}
                      ></Route>
                    </Switch>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function GeneralTab({ entity }) {
  return (
    <Row>
      <Col sm="6">
        <Card className="card-chart">
          <CardHeader>
            <CardTitle className="mt-0">Promotion</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Promotion ID</span>
              </div>
              <div> {entity.id} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Name</span>
              </div>
              <div> {entity.label} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Applies To</span>
              </div>
              <span>
                <span className="text-secondary"></span>
                {entity.applies_to}
              </span>
            </div>
            {/* <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Desc</span>
              </div>
              <div> {entity.description} </div>
            </div> */}
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Start</span>
              </div>
              {entity.from && <div> {formatDate(entity.from)} </div>}
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">End</span>
              </div>
              {entity.to && <div> {formatDate(entity.to)} </div>}
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Amount</span>
              </div>
              {entity.amount && <div> {formatAmount(entity.amount)} </div>}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default connect()(Detail);
