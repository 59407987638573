import {
  CLOSE_ALL_MODALS,
  COMPLETE_REFUND_MODAL,
  CONFIRM_ACTION_MODAL,
  OPEN_ACCOUNT_MODAL,
  OPEN_ADROIT_CHEQUES_MODAL,
  OPEN_API_USERS_REFUND_MODAL,
  OPEN_BALANCE_UPDATE_MODAL,
  OPEN_CAMPAIGN,
  OPEN_CHANNEL_NEW_ORDER_MODAL,
  OPEN_CREATE_MERCHANT_MODAL,
  OPEN_EDIT_COMMISSION_MODAL,
  OPEN_EDIT_SURVEY_MODAL,
  OPEN_GENERATE_MODAL,
  OPEN_GEN_ACCOUNT_MODAL,
  OPEN_MERCHANT,
  OPEN_MERCHANT_MODAL,
  OPEN_PREVIEW_MODAL,
  OPEN_QMCASH,
  OPEN_REFUND_MODAL,
  OPEN_REGENERATE_MODAL,
  OPEN_SEND_NOTIFICATION_MODAL,
  OPEN_SESSION_MODAL,
  OPEN_SUPERVISOR,
  OPEN_SUPERVISOR_LOGS,
  OPEN_TOPUP_WALLET_MODAL,
  OPEN_TRANSACTION,
  OPEN_USER,
  SHOW_APPROVE_MODAL,
  SHOW_CREATE_CAMPAIGN_MODAL,
  SHOW_CREATE_CUSTOMER_MODAL,
  SHOW_CREATE_SUPERVISOR_MODAL,
  SHOW_CREATE_SURVEY_MODAL,
  SHOW_CREATE_VIRTUAL_ACCOUNT_MODAL,
  SHOW_CREATE_WATCHLIST_MODAL,
  SHOW_FACILITY_UPDATE_MODAL,
  SHOW_GEOCODE_ADDRESS_MODAL,
  SHOW_SUPPORT_MSG_MODAL,
  SHOW_SURVEY_RESPONSE_MODAL,
  SHOW_TOKEN_RESPONSE_MODAL,
  SHOW_VEND_RESPONSE,
  SHOW_VOUCHERS_CREATE_MODAL,
  SHOW_WATCHLIST_MODAL,
  TOGGLE_CONFIRM_MODAL_LOADING,
  UPDATE_REFUND_MANIFEST,
  UPDATE_USER_WALLET_MODAL,
} from '../actions/modalActions';

const initState = {
  transaction: false,
  user: false,
  campaign: false,
  supervisor: false,
  supervisorLogs: false,
  refund: false,
  generate: false,
  regenerate: false,
  qmcash: false, // qmcash modal
  session: false,
  apiUser: false,
  adroit: false,
  account: false,
  merchant: false,
  genAccount: false, // monnify generate account
  data: null,
  createMerchant: false,
  confirmAction: false,
  loading: false,
  refundModal: false,
  updateWalletModal: false,
  previewEntity: false,
  vendResponse: false,
  tokenResponse: false,
  refundManifest: {},
  virtualAccount: false,
  createCustomer: false,
  createCampaign: false,
  geoCodeAddress: false,
  facilityUpdate: false,
  addToWatchList: false,
  supportMsg: false,
  voucherCreate: false,
  approveModal: false,
  createSupervisor: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case OPEN_TRANSACTION:
      return { ...initState, transaction: true, data: action.id };

    case OPEN_QMCASH:
      return { ...initState, qmcash: true, data: action.id };

    case OPEN_USER:
      return { ...initState, user: true, data: action.id };

    case OPEN_CAMPAIGN:
      return { ...initState, campaign: true, data: action.id };
    case OPEN_EDIT_SURVEY_MODAL:
      return { ...initState, editSurvey: true, data: action.id };

    case OPEN_SUPERVISOR:
      return { ...initState, supervisor: true, data: action.data };

    case OPEN_MERCHANT:
      return { ...initState, merchant: true, data: action.data };

    case OPEN_REFUND_MODAL:
      return { ...initState, refund: true, data: action.data };

    case OPEN_SUPERVISOR_LOGS:
      return { ...initState, supervisorLogs: true, data: action.data };

    case OPEN_GENERATE_MODAL:
      return { ...initState, generate: true, data: action.data || {} };

    case OPEN_REGENERATE_MODAL:
      return { ...initState, regenerate: true, data: action.data || {} };

    case OPEN_SESSION_MODAL:
      return { ...initState, session: true, data: action.id };

    case OPEN_ACCOUNT_MODAL:
      return { ...initState, account: true };

    case OPEN_MERCHANT_MODAL:
      return { ...initState, merchant: true };

    case OPEN_SEND_NOTIFICATION_MODAL:
      return { ...initState, sendNotification: true, data: action.data };

    case OPEN_ADROIT_CHEQUES_MODAL:
      return { ...initState, adroit: true };

    case OPEN_API_USERS_REFUND_MODAL:
      return { ...initState, apiUser: true };

    case OPEN_CREATE_MERCHANT_MODAL:
      return { ...initState, createMerchant: true, data: action.data };

    case OPEN_BALANCE_UPDATE_MODAL:
      return { ...initState, updateBalance: true, data: action.data };

    case OPEN_TOPUP_WALLET_MODAL:
      return { ...initState, walletTopup: true, data: action.data };

    case UPDATE_USER_WALLET_MODAL:
      return { ...initState, updateWalletModal: true, data: action.data };

    case OPEN_GEN_ACCOUNT_MODAL:
      return { ...initState, genAccount: true };

    case CONFIRM_ACTION_MODAL:
      return { ...initState, confirmAction: true, data: action.data };

    case TOGGLE_CONFIRM_MODAL_LOADING:
      return { ...state, loading: action.data };

    case COMPLETE_REFUND_MODAL:
      return { ...state, refundModal: true, data: action.data };

    case UPDATE_REFUND_MANIFEST:
      return { ...state, refundManifest: action.data };
    case OPEN_PREVIEW_MODAL:
      return { ...state, previewEntity: true, data: action.data || {} };
    case OPEN_CHANNEL_NEW_ORDER_MODAL:
      return { ...state, createChannelOrder: true, data: action.data || {} };
    case OPEN_EDIT_COMMISSION_MODAL:
      return {
        ...state,
        openEditCommissionModal: true,
        data: action.data || {},
      };
    case SHOW_VEND_RESPONSE:
      return { ...state, vendResponse: true, data: action.data || {} };
    case SHOW_TOKEN_RESPONSE_MODAL:
      return { ...state, tokenResponse: true, data: action.data || {} };
    case CLOSE_ALL_MODALS:
      return initState;
    case SHOW_CREATE_VIRTUAL_ACCOUNT_MODAL:
      return { ...state, virtualAccount: true, data: action.data || {} };
    case SHOW_CREATE_CUSTOMER_MODAL:
      return { ...state, createCustomer: true };
    case SHOW_CREATE_CAMPAIGN_MODAL:
      return { ...state, createCampaign: true, data: action.data || {} };
    case SHOW_CREATE_SURVEY_MODAL:
      return { ...state, createSurvey: true };
    case SHOW_SURVEY_RESPONSE_MODAL:
      return { ...state, survey: true, data: action.data || {} };
    case SHOW_GEOCODE_ADDRESS_MODAL:
      return { ...state, geoCodeAddress: true, data: action.data || {} };
    case SHOW_FACILITY_UPDATE_MODAL:
      return { ...state, facilityUpdate: true, data: action.data || {} };
    case SHOW_WATCHLIST_MODAL:
      return { ...state, addToWatchlist: true, data: action.data || {} };
    case SHOW_CREATE_WATCHLIST_MODAL:
      return { ...state, createWatchlist: true };

    case SHOW_SUPPORT_MSG_MODAL:
      return { ...state, supportMsg: true, data: action.data || {} };

    case SHOW_VOUCHERS_CREATE_MODAL:
      return { ...state, voucherCreate: true };

    case SHOW_APPROVE_MODAL:
      return { ...state, approveModal: true };

    case SHOW_CREATE_SUPERVISOR_MODAL:
      return { ...state, createSupervisor: true };

    default:
      return state;
  }
};
