import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './Merchants/List';
import Detail from './Merchants/Detail';

function Refundables() {
  return (
    // <div>
    <Switch>
      <Route exact path="/merchants" component={List} />
      <Route path="/merchants/:id" component={Detail} />
    </Switch>
    // </div>
  );
}

export default Refundables;
