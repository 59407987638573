import React, { useState } from 'react';
import JsonViewer from '../../JsonViewer';
import { callApi } from '../../../utils';

function ShiftEnquries() {
  const [paymentDate, setPaymentDate] = useState('');
  const [result, setResult] = useState([]);

  function fetchData(paymentDate) {
    return callApi(`/ekedc/transactions`, {
      paymentDate,
    }).then((res) => res);
  }

  function handleInputChange(evt) {
    const value = evt.target.value;

    setPaymentDate(value);
  }

  function handleFetchClick() {
    fetchData(paymentDate).then((result) => {
      setResult(result);
    });
  }

  return (
    <div>
      <div>
        <input
          value={paymentDate}
          onChange={handleInputChange}
          placeholder="payment date"
        />
        <button onClick={handleFetchClick}>Fetch Data</button>
      </div>

      <JsonViewer
        src={result}
        name={'Shift Enquiries'}
        iconStyle={'circle'}
        displayDataTypes={false}
        displayObjectSize={false}
      />
    </div>
  );
}

export default ShiftEnquries;
